.serviceC{
    /* background-image: url("/src/assets/fondo21.jpg"); */
    /* background: #039496; */
    background-image: url('/src/assets/fondo37.jpg');
    margin-top: 0%;
    padding-bottom: 5%;
}

.services__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:4rem;
  
}
.letra2{
    color: black;
}
.services{
    background: black;
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.services:hover{
    background: #002A2A;
    border-color: var(--color-primary-variant);
    cursor:default;
}

.services__head{
    background-color:#0298DE ;
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.1) ;
    font-weight: bold;
}

.services__head h3{
    color: black;
    font-size: 1rem;
    text-align: center;
}

.service__list{
    padding: 2rem;
}

.service__list li{
    display: flex;
    gap:1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon{
    color: green;
    margin-top: 4px;
    font-size: large;
}

.service__list p{
    font-size: 1rem;
}

.letra3{
    color: white;
}

.letra4{
    text-align: center;
    color: white;
    font-size: large;
}


@media screen and (max-width:1024px){
    .services__container{
        grid-template-columns: 1fr 1fr;
        gap:1rem;
    }

    .services{
        height: auto;
    }
   

}

@media screen and (max-width:600px){
    
    .services__container{
        grid-template-columns: 1fr;
        gap:2rem;
    }



}

