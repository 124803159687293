.fondoA{
    background-image: url("/src/assets/fondo44.jpg");
    /* background: #039496; */
    /* background-image: url('/src/assets/fondo37.jpg'); */
    padding-top: 5%;
    padding-bottom: 10%;

}

.services__container1{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:3rem;
    padding-left: 1%;
  
}

.card{
    border-radius: 20px;
    background: rgba(12, 45, 65, 0.23);
    transition: all .4s ease-in-out;
    width: 95%;
    padding: 5rem 4rem;
    /* text-justify: distribute; */
}

.subtii{
    text-align: center;
}

.card p{
    padding-top: 1rem;
    line-height: 150%;
}





@media screen and (max-width:1024px){
    .services__container1{
        grid-template-columns: 1fr 1fr;
        gap:2rem;
    }

 
   

}

@media screen and (max-width:600px){

    .fondoA{
        background-image: url("/src/assets/fondo90.jpg");
      
    
    }

 
    
    
    .services__container1{
        display: grid;
        grid-template-columns:1fr;;
        gap:3rem;
        padding-left: 4%;
      
    }



}
