.fondoC{
    background-image: url('/src/assets/fondo70.jpg');
    margin-top: 0%;
    padding-top: 5%;
    padding-bottom: 3%;

    box-sizing: border-box ;
    align-items: center;
    align-content: center;
}
.contact__container{
    
    width: 60%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap:12%;
    margin-left: 20%;
    
}

.contact__options{
    display: flex;
    flex-direction: column;
    gap:1.2rem;
  
}

.contact__option{
    background:linear-gradient(to right, #14163c 0%, #03217b 79%);;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact__option:hover{
    background: linear-gradient(120deg,  rgb(109, 199, 220), #395FF6);;
    border-color: var(--color-primary);
}
.contact__option-icon{
    font-size: 1.5rem;
 
    margin-bottom: 0.5rem;
    color: white;
}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem ;
}


.mee{
    color: white;
}

.letra1{
    color: white;
    font-weight: bold;
}

.h44{
    color: white;
}


/* form */

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: whiet;
    border: 2px solid var(--color-primary);
    resize: none;
    /* color: white; */
}

@media screen and (max-width:1024px){
   .container.contact__container{
        grid-template-columns: 1fr;
        gap:2rem;
    }

}



@media screen and (max-width:600px){

    .fondoC{
        padding-bottom: 55%;
    }
    .container.contact__container{
        grid-template-columns: 1fr;
        margin-left: 8%;
    ;
    }

}